<template>
  <div class="course-apply-container">
    <div style="position: absolute;width: 100%;padding: 20px 12px;box-sizing: border-box;">
      <img src="../../../assets/course_top_logo.png" style="width: 90px;height: 24px;object-fit: cover">
      <img src="../../../assets/course_top_logo_1.png" style="width: 392px;height: 204px;object-fit: cover">
    </div>
    <div class="form-container">
      <div class="form-content">
        <div class="form-content1">
          <el-form label-position="top" :model="applyForm" :rules="rules" ref="ruleForm">
            <el-form-item label="姓" prop="last_name">
              <el-input v-model="applyForm.last_name"></el-input>
            </el-form-item>
            <el-form-item label="名字" prop="given_name">
              <el-input v-model="applyForm.given_name"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="applyForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="applyForm.email"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btn-submit" @click="submitData()">提交</div>
    </div>
  </div>
</template>

<script>
import {fwhApplyCourseApplication, getOpenIdForFWH} from '../../../api/keneng'

export default {
  name: "ApplyApplication",
  data() {
    return {
      showType: 'request_open_id',
      wxCode: '',
      open_id: '',
      applyForm: {
        last_name: '',
        given_name: '',
        mobile: '',
        email: ''
      },
      rules: {
        last_name: [
          {required: true, message: '请输入姓', trigger: 'blur'}
        ],
        given_name: [
          {required: true, message: '请输入名字', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请输入手机号码', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    let showType = 'request_open_id'
    if (this.$route.query.show_type && this.$route.query.show_type == 'show_view') {
      showType = this.$route.query.show_type
      this.wxCode = this.$route.query.code
    }
    if(false){
      // this.open_id = 'oOh4h5375jIgWvoGux_IYmgwNrro'
      this.$router.replace({
        path:'/apply-success',
        query:{
          user_id:'5ee9b932a4640bdc378b456b'
        }
      })
      return
    }
    if (showType == 'request_open_id') {
      this.doRequestOpenId()
    } else {
      this.requestOpenId()
    }
  },
  methods: {

    requestOpenId() {
      getOpenIdForFWH(this.wxCode).then((res) => {
        let result = res.data.data
        this.open_id = result.openid
      })
    },

    doRequestOpenId() {
      const appId = 'wxda915e8dce4fe609'
      const redirectUrl = 'https://login.kenengxueyuan.com/course-apply-application?show_type=show_view'
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect&connect_redirect=1'
    },
    submitData() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            open_id: this.open_id,
            mobile: this.applyForm.mobile,
            email: this.applyForm.email,
            last_name: this.applyForm.last_name,
            given_name: this.applyForm.given_name
          }
          fwhApplyCourseApplication(params).then((res) => {
            let result = res.data
            if (result.code == 0) {
              let user_id = result.data.user_id
              this.$router.replace({
                path:'/apply-success',
                query:{
                  user_id:user_id
                }
              })
              this.$message.success('报名成功～')
            } else {
              this.$message.success(result.msg)
            }
          })
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
  color: white;
}

.course-apply-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/course_bg.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  overflow-y: auto;
}

.form-container {
  width: 100%;
  position: absolute;
  margin-top: 200px;
  padding: 20px 12px;
  box-sizing: border-box;
}

.form-content {
  width: 100%;
  border-radius: 19px 19px 19px 19px;
  border: 2px dashed #FFFFFF;
  box-sizing: border-box;
  padding: 5px;
}

.form-content1 {
  padding: 30px 25px;
  width: 100%;
  min-height: 500px;
  border-radius: 19px 19px 19px 19px;
  box-sizing: border-box;
  background-color: #5D5BB5;
}

.btn-submit {
  margin-top: 50px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  height: 52px;
  line-height: 52px;
  background: #FFA831;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}


</style>
